<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar :toolbarName="dynamicToolbarName" :openDetails="openDetails"/>

    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formSuccessAdvisor">
        <v-toolbar flat v-if="canToggleUser">
          <v-switch
              v-model="payload.is_active"
              :label="getStatus(payload.is_active)"
              color="red"
              @change="toggleUser(payload.id)"
              :value="payload.is_active"
              hide-details
          ></v-switch>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome"/>
            <simple-text-field v-model="payload.name"
                               required
                               placeholder="Nome"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="E-mail"/>
            <simple-text-field v-model="payload.email"
                               required
                               email
                               placeholder="seu@email.com.br"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Telefone"/>
            <simple-text-field v-model="payload.phone"
                               mask="phone"
                               placeholder="Telefone"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Estado"/>
            <simple-select :items="states"
                           v-model="payload.state_id"
                           placeholder="Selecione o Estado"
                           itemText="name"
                           itemValue="id"
                           :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
                           required
                           :rules="requiredRules"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6" v-if="!openDetails">
            <custom-label label="Status"/>
            <simple-select
              v-model="payload.is_active"
              :items="statusType"
              itemText="text"
              itemValue="value"
              class="mr-2"
              placeholder="Status"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
            />
          </v-col>
        </v-row>

        <v-row v-if="this.openDetails">
          <v-col cols="auto">
            <span class="font-weight-bold">Senha</span> <br/>

            <primary-button
                label="Nova Senha"
                style="margin-top: 0.3rem;"
                type="button"
                @callAction="showConfirmResetPasswordDialog = true"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails" v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select
          v-model="isActiveSearch"
          :items="statusType"
          @input="filterByStatus()"
          itemText="text"
          itemValue="value"
          class="mr-2"
          placeholder="Status"
          height="0"
          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
      />
    </search-bar>

    <row-details v-if="openDetails" :show="openDetails">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold font-17">Informações Gerais</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-icon @click="edit()"> mdi-pencil</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <col-details v-if="payload.name != null" name="Nome" :item="payload.name"/>
        <col-details v-if="payload.email != null" name="E-mail" :item="payload.email"/>
        <col-details v-if="payload.state != null" name="Estado" :item="payload.state.name"/>
      </v-row>

      <template v-slot:[`other-title-details`]>
        <v-row class="mt-1">
          <v-col>
            <span class="font-weight-bold">Produtores Assessorados</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`other-row-details`]>
        <data-table
            v-if="dataProducers.length > 0"
            :headers="headersProducers"
            :fetching="fetchingProducers"
            :data="dataProducers"
            class="data-table-producers">

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon style="color:#707070;" class="ml-4" small @click="viewProducer(item)">
              mdi-information-outline
            </v-icon>
          </template>
        </data-table>

        <span v-else class="font-weight-bold">
          Não existe nenhum produtor vinculado
        </span>
      </template>

    </row-details>

    <data-table :headers="headers"
                :fetching="fetching"
                :data="data"
                :search="search.text"
                :objectName="objectName"
                @loadAgain="loadAgain"
                v-else
    >
      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon style="color:#707070;" class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon v-if="authoritySlug === 'super-admin' || permissions.access_as"
          class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-reset-password-dialog
      :payload="this.payload"
      :dialog.sync="showConfirmResetPasswordDialog"
      @closeDialog="closeConfirmResetPasswordDialog"
    />

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />

    <!-- Atualizar assessor de sucesso de produtores antes de remover
         assessor de sucesso
    -->
    <confirm-update-dialog :dialog="showConfirmUpdateDialog"
      :formName="payload.name" :textUpdate="'Prosseguir'">
      <v-row>
        <v-col cols="12">
          <v-toolbar elevation="0" style="background: none">
            <v-toolbar-title class="font-weight-bold"
              style="overflow: unset; white-space:unset;">
              {{ message }}
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <custom-label label="Assessor de Sucesso" />
          <v-form ref="formProducerAs">
            <simple-select
              :items="successAdvisorsFilter"
              v-model="selectedProducerAs"
              itemText="name"
              itemValue="id"
              placeholder="Selecione um assessor de sucesso"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }"
              required
              :rules="requiredRules"
            />
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <data-table
          style="margin-left:12px;"
          :headers="headersProducersByAs"
          :data="dataProducers"
        />
      </v-row>
    </confirm-update-dialog>
  </div>
</template>

<script>
import DataTable from "@/components/data-table/DataTable";
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import RowDetails from "@/components/crud/Details";
import ColDetails from "@/components/crud/DetailsItem";
import CustomLabel from "@/components/texts/CustomLabel";
import Users from "@/domain/users/users";
import Authorities from "@/domain/authorities/authorities";
import States from "@/domain/states/states";
import Producers from "@/domain/producers/producers";
import PageableMixin from "@/support/utils/pageable.mixin";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import ConfirmResetPasswordDialog from "@/components/dialogs/login/ConfirmResetPasswordDialog";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";
import ConfirmUpdateDialog from "@/components/crud/ConfirmUpdateDialog";
import {getItem} from '@/services/local-storage.service';

export default {
  name: 'SuccessAdvisors',
  mixins: [PageableMixin],
  components: {
    DataTable,
    Toolbar,
    FormDialog,
    RowDetails,
    ColDetails,
    CustomLabel,
    PrimaryButton,
    SimpleTextField,
    SearchBar,
    SimpleSelect,
    ConfirmResetPasswordDialog,
    ConfirmDestroyDialog,
    ConfirmUpdateDialog
  },
  data() {
    return {
      statusType:
          [
            {text: "Ativo", value: true},
            {text: "Inativo", value: false}
          ],

      objectName: 'assessores de sucesso',
      toolbarName: 'Lista de Assessores de Sucesso',
      formName: 'Adicionar Assessor de Sucesso',

      fetching: true,
      search: {},
      isActiveSearch: "",
      data: [],
      authorities: [],
      states: [],
      fetchingProducers: false,
      dataProducers: [],
      successAdvisorSelected: {},

      userService: null,
      authorityService: null,
      stateService: null,

      payload: null,
      selected: null,
      canToggleUser: false,

      slug: "success-advisor",
      authority_id: 0,

      openDetails: false,
      formDialog: false,
      status: false,
      showConfirmResetPasswordDialog: false,
      showConfirmDestroyDialog: false,
      showConfirmUpdateDialog: false,

      successAdvisorsFilter: [],
      selectedProducerAs: null,
      message: "Os produtores abaixo possuem vínculo com o assessor \
        de sucesso que deseja-se excluir, para prosseguir com a deleção \
        por favor selecione um novo assessor de sucesso para esses produtores.",

      authoritySlug: '',
      permissions: '',

      requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
    }
  },
  methods: {
    getUsers() {
      this.fetching = true;
      this.data = [];

      this.userService.list().then((result) => {
        result.data.map((itm) => {
          if (itm.authority.slug === this.slug) {
            this.data.push(itm);

            this.setSuccessAdvisorSelected(itm);
          }
        });
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });

      this.fetching = false;
    },
    setSuccessAdvisorSelected(user) {
      if (this.$route.params.asId === user.id) {
        this.successAdvisorSelected = user;
        this.view(this.successAdvisorSelected);
      }
    },
    async getProducersByAS(successAdvisorId) {
      this.fetching = true;
      this.dataProducers = [];

      await this.producerService.getProducersByAS(successAdvisorId).then(result => {
        result.data.map(item => {
          this.dataProducers.push(item);
        });
      });

      this.fetching = false;
    },
    getAuthority() {
      let loader = this.$loading.show();

      this.authorityService.getBySlug(this.slug).then((result) => {
        result.data.map((itm) => {
          this.authority_id = itm.id;
        });
      });
      loader.hide();
    },
    getStates() {
      let loader = this.$loading.show();

      this.stateService.list().then((result) => {
        result.data.map((itm) => {
          this.states.push(itm);
        });
      });
      loader.hide();
    },
    getStatus(status) {
      if (status === true) return "Ativo";

      return "Inativo";
    },
    toggleUser(id) {
      let loader = this.$loading.show();

      Users.toggle(id)
          .then(() => {
            loader.hide();
            this.$toasted.show("Status alterado com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
    },
    initialize() {
      this.userService = Users;
      this.authorityService = Authorities;
      this.stateService = States;
      this.producerService = Producers;
      this.payload = this.userService.newData();

      this.authoritySlug = getItem('authoritySlug');
      this.permissions   = JSON.parse(getItem('permissions'));

      this.getUsers();
      this.getAuthority();
      this.getStates();
    },
    view(item) {
      this.getProducersByAS(item.id);
      this.payload = Object.assign({}, item);
      this.selected = Object.assign({}, item);
      this.openDetails = true;
    },
    viewProducer(item) {
      this.navigateTo('producers', {producerId: item.id});
    },
    closeDetails() {
      this.payload = {};
      this.selected = {};
      this.openDetails = false;
    },
    create() {
      this.payload = {};
      this.payload.is_active = true;
      this.selected = {};
      this.canToggleUser = false
      this.formDialog = true;
    },
    edit() {
      this.canToggleUser = true
      this.formDialog = true;
    },
    async deleteItem(item) {
      this.payload = Object.assign({}, item);

      this.selectedProducerAs    = null;
      this.successAdvisorsFilter = this.data;
      this.successAdvisorsFilter = this.successAdvisorsFilter.filter(
        as => as.id !== item.id
      );

      await this.getProducersByAS(this.payload.id);

      if (this.dataProducers.length !== 0) {
        this.showConfirmUpdateDialog = true;
      } else {
        this.showConfirmDestroyDialog = true;
      }
    },
    update() {
      if (!this.$refs.formProducerAs.validate()) return;
      this.showConfirmDestroyDialog = true;
    },
    updateProducersAs() {
      let loader = this.$loading.show();

      this.producerService
      .updateProducersAs(this.selectedProducerAs, this.payload.id)
      .then(() => {
        loader.hide();
        this.$toasted.show("Assessor dos produtores atualizado.", {
          type: 'success'
        });
      })
      .catch(({response}) => {
        loader.hide();
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });
    },
    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },
    closeConfirmUpdateDialog() {
      this.showConfirmUpdateDialog = false;
      this.toggleScrollBar('auto');
    },
    closeFormDialog() {      
      this.selected = {};
      this.formDialog = false;
    },
    closeView() {
      this.openDetails = false;
      this.$route.params.asId = null;
      this.initialize();
    },
    save() {
      if (!this.$refs.formSuccessAdvisor.validate()) {
        return;
      }

      let loader = this.$loading.show();

      this.payload.authority_id = this.authority_id;
      this.payload.is_active = this.payload.is_active === null
        ? false : this.payload.is_active;

      if (!this.openDetails) {
        this.userService.create(this.payload)
            .then(() => {
              loader.hide();
              this.closeFormDialog();
              this.initialize();
              this.$toasted.show("Usuário cadastrado com sucesso.", {
                type: "success",
              });
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      } else {
        this.userService.edit(this.payload)
            .then(() => {
              loader.hide();

              const stateSelected = this.states.find(
                item => item.id === this.payload.state_id
              );

              this.payload.state.name = stateSelected.name;
              this.closeFormDialog();
              this.$toasted.show("Usuário atualizado com sucesso.", {
                type: "success",
              });
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      }
    },

    async destroy() {
      if (this.showConfirmUpdateDialog) await this.updateProducersAs();

      await this.destroySuccessAdvisor();
    },
    destroySuccessAdvisor() {
      const loader = this.$loading.show();

      this.userService.delete(this.payload)
        .then(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.closeConfirmUpdateDialog();
          this.getUsers();
          this.$toasted.show("Assessor removido com sucesso.", {
            type: "success",
          });
        })
        .catch(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.closeConfirmUpdateDialog();
          this.$toasted.show("Assessor não foi removido.", {
            type: 'error'
          });
        });
    },

    filterByStatus() {
      this.search.is_active = this.isActiveSearch;
    },

    clearFilters() {
      this.search = {};
      this.isActiveSearch = "";
      this.payload.is_active = "";
    },

    closeConfirmResetPasswordDialog() {
      this.showConfirmResetPasswordDialog = false;
    },
    loadAgain() {
      if (!this.openDetails) {
        this.initialize();
      } else {
        this.getProducersByAS();
      }
    },
    toggleScrollBar(param) {
      let html = document.querySelector('html');
      html.setAttribute("style", `overflow: ${param};`);
    }
  },
  computed: {
    headers() {
      return [
        {text: "ID", align: "start", value: "id"},
        {text: "Nome", value: "name"},
        {text: "Status", value: "is_active",
          filter: value => {
            if (this.isActiveSearch === "") return true
            return value === this.search.is_active;
          }
        },
        {text: "Detalhes", value: "actions", sortable: false},
      ]
    },

    headersProducers() {
      let headersProducers = [
        {text: "ID", align: "start", value: "id"},
        {text: "Nome", value: "user.name"},
        {text: "Estado", value: "user.state.uf"},
      ];

      if (this.authoritySlug === 'super-admin' || this.permissions.access_producers) {
        headersProducers.push(
          {text: "Detalhes", value: "actions", sortable: false},
        );
      }

      return headersProducers;
    },

    headersProducersByAs() {
      return [
        {text: "ID", align: "start", value: "id"},
        {text: "Nome", value: "user.name"},
        {text: "Assessor de Sucesso", value: "responsible_success_advisor.name"}
      ];
    },

    dynamicToolbarName() {
      if (this.openDetails) {
        return this.toolbarName + ' / Detalhes';
      }

      return this.toolbarName;
    },
    dynamicFormName() {
      if (this.openDetails) {
        return "Editar Assessor de Sucesso";
      }

      return this.formName;
    }
  },
  watch: {
    showConfirmDestroyDialog: {
      immediate: true,
      handler () {
        if (this.showConfirmUpdateDialog) this.toggleScrollBar('hidden');
      }
    }
  },
  beforeMount() {
    this.initialize();
  }
}
</script>

<style scoped>
.data-table-producers {
  border: none !important;
  padding: 0 !important;
}

.font-17 {
  font-size: 17px;
}
</style>