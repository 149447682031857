<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text>
          <v-icon @click="close">mdi-close</v-icon>
        </v-btn>
        <span class="white--text">{{ formName }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="update" color="white" class="font-17" text> {{ textUpdate }} </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <slot></slot>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmUpdateDialog",
  props: ['dialog', 'formName', 'textUpdate'],
  methods: {
    close() {
      this.$parent.closeConfirmUpdateDialog();
    },
    update() {
      this.$parent.update();
    }
  }
}
</script>

<style scoped>
.font-17 {
  font-size: 17px;
}
</style>
